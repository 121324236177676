import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const findAll = async (query) => {
  return axios.request({
    method: 'GET',
    url: '/api/services',
    params: query,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const getAllCurrentServices = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/services/current-services',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const exportReport = () => {
  return axios.request({
    method: 'GET',
    url: '/export/file',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const getAllFinancialLaunchServices = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/services/financial',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveService = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/services',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveServicePassengers = async (id, data) => {
  return axios.request({
    method: 'POST',
    url: `/api/services/passengers/${id}`,
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateService = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/services/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const getReportService = async (customerId, startDate, finishDate) => {
  return axios.request({
    method: 'GET',
    url: `/api/services/report/client?customer=${customerId}&startDate=${startDate}&endDate=${finishDate}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, responseType: 'blob' },
  })
}

const deleteService = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/services/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  findAll,
  getReportService,
  getAllCurrentServices,
  getAllFinancialLaunchServices,
  saveService,
  saveServicePassengers,
  deleteService,
  updateService,
  exportReport
}
