<template>
  <div class="recipes">
    <button
      class="button field is-info is-light z-10"
      @click="isExportServiceModalActive = true"
    >
      <span>Baixar relatório</span>
    </button>
    <b-modal v-model="isExportServiceModalActive" :width="640" scroll="keep">
      <div class="card" style="height: 700px">
        <div class="card-content">
          <div class="content">
            <b-field label="Clientes">
              <multiselect
                v-model="selectedCustomers"
                :options="allCustomers"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Selecione os serviços"
                label="name"
                track-by="id"
                :preselect-first="false"
              ></multiselect>
            </b-field>
            <b-field label="Selecione o período">
              <b-datepicker
                placeholder="Clique para selecionar"
                v-model="dates"
                inline
                range
              >
              </b-datepicker>
            </b-field>
            <downloadexcel
              class="button field is-info is-light z-10"
              :data="data"
              :fields="fields"
              worksheet="Relatório"
              :name="fileName"
              :fetch="downloadReport"
            >
              Download Excel
            </downloadexcel>
          </div>
        </div>
      </div>
    </b-modal>
    <b-table
      :data="allFinancialServiceLaunch"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="id"
        label="OS"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon"># {{ props.row.service_id }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)"
            >#{{ props.row.service_id }}</a
          >
        </template>
      </b-table-column>

      <b-table-column
        field="cost_center"
        label="Centro de custo"
        :searchable="true"
        sortable
        v-slot="props"
        >{{
          props.row.customer && props.row.customer.cost_center
            ? props.row.customer.cost_center.code
            : 'N/A'
        }}</b-table-column
      >

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.name.toUpperCase() }}</b-table-column
      >

      <b-table-column
        field="value"
        label="Valor"
        :searchable="true"
        sortable
        v-slot="props"
        >R$ {{ parseFloat(props.row.value).toFixed(2) }}</b-table-column
      >

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.name }}</strong>
                <br />
                <strong>Valor:</strong>
                <small>R$ {{ parseFloat(props.row.value).toFixed(2) }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
import service from '../features/services/store/service'
import { mapActions, mapState } from 'vuex'
import downloadexcel from 'vue-json-excel'

export default {
  name: 'FinancialServiceLaunch',
  components: {
    downloadexcel,
  },
  data() {
    return {
      showDetailIcon: false,
      isExportServiceModalActive: false,
      selectedCustomers: '',
      allFinancialServiceLaunch: [],
      fileName: 'file.xls',
      fields: {
        DATA: 'tempDate',
        CLIENTE: 'customer',
        OS: 'id',
        PASSAGEIRO: 'passenger',
        MATRÍCULA: 'registration',
        CC: 'costCenter',
        HORÁRIO: 'boarding_time',
        ITINERÁRIO: 'itinerary',
        SOLICITANTE: 'requester',
        VALOR: 'value',
        MOTORISTA: 'driver',
      },
      data: [],
      dates: [],
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('customers', ['allCustomers']),
    ...mapState('services', ['allServices']),
  },
  methods: {
    ...mapActions('customers', ['getAllCustomers']),
    ...mapActions('services', ['getAllServices']),

    async init() {
      await this.getAllCustomers()
      await this.getAllServices()
      const result = await service.getAllFinancialLaunchServices()
      this.allFinancialServiceLaunch = result.data
    },

    async downloadReport() {
      const tempServices = []

      // this.allServices
      //   .filter((x) => x.status !== 'Cancelado')
      //   .forEach((x) => {
      //     const serviceDate = new Date(x.service_date)
      //     const now = new Date()
      //   })

      const result = await service.getReportService(
        this.selectedCustomers.id,
        new Date(this.dates[0]).toISOString().split('T')[0],
        new Date(this.dates[1]).toISOString().split('T')[0]
      )

      console.log('result ', result)

      const resultData = result.data.map((x) => {
        const date = this.$moment(x.date).format('DD/MM/YYYY')
        return {
          tempDate: date,
          ...x,
        }
      })

      this.data = resultData

      this.fileName = `Relatório de viagens ${this.selectedCustomers.name} (${
        new Date(this.dates[0]).toISOString().split('T')[0]
      } - ${new Date(this.dates[1]).toISOString().split('T')[0]}).xls`

      return resultData
    },
  },
}
</script>

<style scoped></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
